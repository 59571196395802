.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .loader {
    height: 6vh;
    width: 6vh;
    border: 2px solid;
    border-color:  #FB3310 transparent #FB3310 ;
    border-radius: 50%;
    animation: spin 1s linear infinite;
   
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
 
 
  